import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout"
import Seo from "../../components/Seo";

const CatalogItemPage = () => (
	<Layout>
		<Seo title="Look at What You've Got" />

		<h2>Look at What You've Got</h2>

		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST16 look what you got front.jpg"
			alt="Jesse Rivest - Look at What You've Got - cover art"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h3>Prerelease note</h3>
		<p>
			To be released on November 3, 2023.<br />
			(Actually released to streaming platforms on October 30 to allow time for uploading of lyrics.)<br />
			This is a single from an upcoming release, <Link to="/music/17/"><span className="italic">Distant December</span></Link>,
			which will be released on December 1, 2023.
		</p>

		<h3>Words from Jesse</h3>
		<p>
			This track was built up similarly to the last one, <Link to="/music/15"><span className="italic">No Lights Shine Out to Me</span></Link>;
			I got it started at home with a basic rhythm of programmed drums and an arpeggiating guitar.
			When Misael and I went to Orbis Studio to record the drums for <span className="italic">No Lights Shine Out to Me</span>,
			we brought this one along with us as well;
			we recorded the drums and any percussion for both songs that same day.
			In terms of engineering, we didn't change a thing between the songs—just the energy.
			We recorded <span className="italic">No Lights Shine Out to Me</span> first and then relaxed a bit to record <span className="italic">Look at What You've Got</span>.
		</p>
		<p>
			Like the last song, I sent this one to Alexander (Sasha) Raichenok and he came back with some Hammond B3 that I was stoked to hear.
			You're hearing it just as he played it, minus one chord coming out of part C that was miscommunicated; I corrected that in the MIDI.
			Yes, MIDI; Sasha doesn't have a B3 nor a Leslie, so I ran his Nord MIDI through some cool plugins and voila!
			Murilo wasn't available this time to add his bass guitar, so I added some organ bass myself.
			I hope that all doesn't sound too boring?
			I'm in Guarulhos Airport (São Paulo) waiting for my flight to Toronto;
			I'm trying to write something interesting here over the constant spitty, monotonous bursts of intercom babble.
			Turns out they're actually calling my name and I gotta hurry now to get a new boarding pass.
		</p>
		<p>
			This is a wintery song; I was thinking of the holidays.
			I was imagining someone feeling alone; are any of us really alone?
			I know we might think or feel that.
			It's quite a phenomenon, as there are usually so many people around us in our lives.
		</p>
		<p>
			I hope you enjoy <span className="italic">Look at What You've Got</span>.
			Please share it if you think it might touch someone;
			you can also <Link to="/contact/">drop me a line</Link> if it resonates with you 😉.
		</p>

		<h3>Track listing</h3>
		<ol>
			<li>
				Look at What You've Got (4:31)<br />
				T3209953215 - © Jesse Rivest 2023 (SOCAN/BMI, CMRRA)<br />
				CA-6P8-23-00003 - © Jesse Rivest 2023 (PPL)
			</li>
		</ol>
		<p>
			This recording: catalog number JRIVEST16, © Jesse Rivest 2023, All Rights Reserved<br />
			UPC/EAN: 0198009369515<br />
			Released: November 3, 2023
		</p>

		<h3>Credits</h3>
		<p>
			Produced, recorded, mixed, and mastered by Jesse Rivest at his Little Bakery Studio in Brasilia, DF, Brazil.<br />
			Drums recorded by Marcos Pagani at Orbis Studio in Brasilia, DF, Brazil.<br />
			Song written and performed by Jesse Rivest.<br />
			Drums and percussion performed by Misael Barros.<br />
			Hammond B3 performed by Alexander Raichenok.<br />
			Organ bass performed by Jesse Rivest.<br />
			Electric guitar performed by Jesse Rivest.<br />
			Backing vocals performed by Jesse Rivest.<br />
			Album cover artwork by Paula Calderón (www.paulacalderon.com.br, @paulacalderon.arte, 2023).<br />
			Album cover layout by Jesse Rivest.
		</p>

		<h3>Special Thanks</h3>
		<p>
			Thanks to Jane Lino for being supportive, always.<br />
			Thanks to Misael and Alexander for their performances!<br />
			Thanks to Tico de Moraes for his ears and feedback.
		</p>

		<h3>Extras</h3>

		<h4>Back cover</h4>
		<p>
			Please see <Link to="/music/17/"><span className="italic">Distant December</span></Link> for its back cover;
			I decided not to design a back cover for this single.
		</p>

		<h3 className="lyrics">Lyrics</h3>
		<div>
			<h4 className="lyrics__title">
				Look at What You've Got</h4>
			<h5 className="lyrics__copyright">
				© Jesse James Rivest, 2023
			</h5>
			<p>
				Oh, here come old memories<br />
				Oh, how they shimmer bright as stars<br />
				How I love all them for what they were<br />
				Even wish I could stop and return
			</p>
			<p>
				Oh, they take me back, those old songs<br />
				But I know that's not where I belong
			</p>
			<p>
				Oh, here come the holidays<br />
				Still don't have any plans, just time free<br />
				How I long just to go far away<br />
				Fly to some warmer place and free fall<br />
			</p>
			<p>
				Oh, they take me back, those strung lights<br />
				And the songs we'd sing those cold nights
			</p>
			<p>
				Take in who you've got around you now<br />
				Look at where you're at and don't look down<br />
				Find someone who needs a friendly smile<br />
				And share with them all that you've got
			</p>
			<p>
				Snow is falling, a soft blanket accrues<br />
				Through the windows kids are staring at you<br />
				How you're dancing and entrancing<br />
				You're the last thing they'll see<br />
				As they fall asleep
			</p>
			<p>
				Look at what you've got around you now<br />
				Enjoy where you're at and don't look down<br />
				Find someone who needs a friendly smile<br />
				And share with them all that you've got
			</p>
		</div>

		<p>
			<Link to="../">Go back to Music Catalog</Link>
		</p>
	</Layout>
);

export default CatalogItemPage;
